
import { defuFn } from '/app/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "firebaseConfig": {
    "apiKey": "AIzaSyDOYvpGfxdE85t3HvVU-GhWyZg-tX782vQ",
    "authDomain": "enee-dev.firebaseapp.com",
    "databaseURL": "https://enee-dev-default-rtdb.firebaseio.com/",
    "projectId": "enee-dev",
    "storageBucket": "enee-dev.appspot.com",
    "messagingSenderId": "408411139805",
    "appId": "1:408411139805:web:d2e180dfa844d4d3ce3534",
    "measurementId": "G-WV0L5G93JY"
  },
  "vuefireOptions": {
    "optionsApiPlugin": false,
    "emulators": {
      "enabled": true
    },
    "config": {
      "apiKey": "AIzaSyDOYvpGfxdE85t3HvVU-GhWyZg-tX782vQ",
      "authDomain": "enee-dev.firebaseapp.com",
      "databaseURL": "https://enee-dev-default-rtdb.firebaseio.com/",
      "projectId": "enee-dev",
      "storageBucket": "enee-dev.appspot.com",
      "messagingSenderId": "408411139805",
      "appId": "1:408411139805:web:d2e180dfa844d4d3ce3534",
      "measurementId": "G-WV0L5G93JY"
    }
  }
}



export default /* #__PURE__ */ defuFn(inlineConfig)
