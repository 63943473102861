import { default as indexScj6e3C9o7Meta } from "/app/pages/adendas-contratos/index.vue?macro=true";
import { default as _91id_93nLTSODSvdqMeta } from "/app/pages/component/[id].vue?macro=true";
import { default as indexxNPXGorrcDMeta } from "/app/pages/control-interno/index.vue?macro=true";
import { default as _91id_93Pn7Q7gtBo7Meta } from "/app/pages/files/[id].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93Yfo0jd1IWDMeta } from "/app/pages/new/[id].vue?macro=true";
import { default as indexJ4ow9ryH0IMeta } from "/app/pages/noticias/index.vue?macro=true";
import { default as indextUy02lndyTMeta } from "/app/pages/otras-areas/index.vue?macro=true";
import { default as _91id_9385VdxFiDQKMeta } from "/app/pages/page/[id].vue?macro=true";
import { default as indexvmiFkZJ3j8Meta } from "/app/pages/sobre-nosotros/index.vue?macro=true";
export default [
  {
    name: indexScj6e3C9o7Meta?.name ?? "adendas-contratos",
    path: indexScj6e3C9o7Meta?.path ?? "/adendas-contratos",
    meta: indexScj6e3C9o7Meta || {},
    alias: indexScj6e3C9o7Meta?.alias || [],
    redirect: indexScj6e3C9o7Meta?.redirect || undefined,
    component: () => import("/app/pages/adendas-contratos/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nLTSODSvdqMeta?.name ?? "component-id",
    path: _91id_93nLTSODSvdqMeta?.path ?? "/component/:id()",
    meta: _91id_93nLTSODSvdqMeta || {},
    alias: _91id_93nLTSODSvdqMeta?.alias || [],
    redirect: _91id_93nLTSODSvdqMeta?.redirect || undefined,
    component: () => import("/app/pages/component/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxNPXGorrcDMeta?.name ?? "control-interno",
    path: indexxNPXGorrcDMeta?.path ?? "/control-interno",
    meta: indexxNPXGorrcDMeta || {},
    alias: indexxNPXGorrcDMeta?.alias || [],
    redirect: indexxNPXGorrcDMeta?.redirect || undefined,
    component: () => import("/app/pages/control-interno/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Pn7Q7gtBo7Meta?.name ?? "files-id",
    path: _91id_93Pn7Q7gtBo7Meta?.path ?? "/files/:id()",
    meta: _91id_93Pn7Q7gtBo7Meta || {},
    alias: _91id_93Pn7Q7gtBo7Meta?.alias || [],
    redirect: _91id_93Pn7Q7gtBo7Meta?.redirect || undefined,
    component: () => import("/app/pages/files/[id].vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Yfo0jd1IWDMeta?.name ?? "new-id",
    path: _91id_93Yfo0jd1IWDMeta?.path ?? "/new/:id()",
    meta: _91id_93Yfo0jd1IWDMeta || {},
    alias: _91id_93Yfo0jd1IWDMeta?.alias || [],
    redirect: _91id_93Yfo0jd1IWDMeta?.redirect || undefined,
    component: () => import("/app/pages/new/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJ4ow9ryH0IMeta?.name ?? "noticias",
    path: indexJ4ow9ryH0IMeta?.path ?? "/noticias",
    meta: indexJ4ow9ryH0IMeta || {},
    alias: indexJ4ow9ryH0IMeta?.alias || [],
    redirect: indexJ4ow9ryH0IMeta?.redirect || undefined,
    component: () => import("/app/pages/noticias/index.vue").then(m => m.default || m)
  },
  {
    name: indextUy02lndyTMeta?.name ?? "otras-areas",
    path: indextUy02lndyTMeta?.path ?? "/otras-areas",
    meta: indextUy02lndyTMeta || {},
    alias: indextUy02lndyTMeta?.alias || [],
    redirect: indextUy02lndyTMeta?.redirect || undefined,
    component: () => import("/app/pages/otras-areas/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9385VdxFiDQKMeta?.name ?? "page-id",
    path: _91id_9385VdxFiDQKMeta?.path ?? "/page/:id()",
    meta: _91id_9385VdxFiDQKMeta || {},
    alias: _91id_9385VdxFiDQKMeta?.alias || [],
    redirect: _91id_9385VdxFiDQKMeta?.redirect || undefined,
    component: () => import("/app/pages/page/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvmiFkZJ3j8Meta?.name ?? "sobre-nosotros",
    path: indexvmiFkZJ3j8Meta?.path ?? "/sobre-nosotros",
    meta: indexvmiFkZJ3j8Meta || {},
    alias: indexvmiFkZJ3j8Meta?.alias || [],
    redirect: indexvmiFkZJ3j8Meta?.redirect || undefined,
    component: () => import("/app/pages/sobre-nosotros/index.vue").then(m => m.default || m)
  }
]