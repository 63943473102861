import { ThemeDefinition, createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { VDataIterator } from "vuetify/labs/VDataIterator";
import { VDataTable } from "vuetify/lib/labs/components.mjs";

const myTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#5fd0df',
    secondary: '#f69224',
    success: '#42b883',
    info: '#f69224',
    warning: '#f69224',
    error: '#f44336'
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: true,
    components: {
      ...components,
      VDataIterator,
      VDataTable,
    },
    defaults: {
      VTextField: {
        variant: 'outlined',
        color: 'primary'
      }

    },
    theme: {
      defaultTheme: "myTheme",
      themes: {
        myTheme
      }
    },
    directives
  });

  nuxtApp.vueApp.use(vuetify);
});
