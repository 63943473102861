<template>
    <NuxtLayout :name="404">
        <div class="container-404">

            <div class="logo-404">
                <NuxtImg src="/404.svg" />
            </div>
            <v-btn class="font-bold px-12 v-btn"  color="primary" dark @click="goBack">Inicio</v-btn>
        </div>
    </NuxtLayout>
</template>
  
<script>
export default {
    methods: {
        goBack() {
            this.$router.push('/');
        },
    },
};
</script>
  
<style scoped lang="scss">
@import "./assets/scss/responsive";

.container-404{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.logo-404 {
    width: 50%;
    @include responsive(mobile) {
    width: 100%;
  }
    @include responsive(small) {
    width: 100%;
  }

}

</style>
